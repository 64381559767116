function loadGoogleAnalytics(){
    let ga = document.createElement('script'); 
    ga.async = true;
    ga.src = 'https://www.googletagmanager.com/gtag/js?id=G-K6D2RMRRRY';
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ga, s);

    let ga2 = document.createElement("script");
    let inlineScript = document.createTextNode("window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-K6D2RMRRRY');");
    ga2.appendChild(inlineScript); 
    s.parentNode.insertBefore(ga2, s);
}

loadGoogleAnalytics(); 